import React from "react"
import { FlexGrid } from "./style"
import { MainColumn } from "ui/MainColumn"

//Documentation
//https://www.npmjs.com/package/react-styled-flexboxgrid
//https://loicmahieu.github.io/react-styled-flexboxgrid/demo/index.html

const Grid = (props) => {
  if (props.mainColumn) {
    return (
      <MainColumn>
        <FlexGrid {...props} fluid />
      </MainColumn>
    )
  } else {
    return <FlexGrid {...props} fluid />
  }
}

export default Grid
