import styled from "styled-components"
import { device } from "breakpoints"

export const NavListItem = styled.li`
  display: flex;
  width: 100%;
  font-weight: 600;
  align-items: center;
  color: ${(props) => props.theme.linkColor};
 
  @media ${device.desktop} {
    width: auto;
    position: relative;
    color: white;
    color: ${(props) => props.sticky && "#163C67"};
    font-size: ${(props) => props.sticky && "14px !important"};

    font-size: 15px;
  }

  @media ${device.large} {
    font-size: 16px;
  }

  a {
    display: flex;
    color: inherit;
    padding: 0px 40px;
    width: 100%;
    position: relative;

    @media ${device.desktop} {
      padding: 0 20px;
    }

    ::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  span {
    position: relative;
    border-bottom: 1px solid #f1f1f1;
    width: 100%;
    padding: 14px 0;

    @media ${device.desktop} {
      border: none;
      padding: 0;
    }
    /* hover underline style */
    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: white;
      background: ${(props) => props.sticky && "#163C67"};
    }
  }

  :last-of-type {
    span {
      border: none;
    }
  }

  /* desktop hover styles */
  @media ${device.desktop} {
    :hover {
      > a {
        /* shows nav item underline */
        span::before {
          opacity: 1;
          bottom: -3px;
        }
      }
    }
  }
`
