import React from "react"
import { NotFoundComponentContainer } from "./style"
import { Button } from "ui"
import AvenueImg from "ui/AvenueImg"
import { useStaticQuery, graphql } from "gatsby"

const NotFoundComponent = () => {
  const { bg } = useStaticQuery(
    graphql`
      query {
        bg: file(relativePath: { eq: "404-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <NotFoundComponentContainer>
      <h1>Sorry,</h1>
      <p>the page that you are looking for doesn't exist...</p>
      <Button variant="white" to="/">
        Back to home
      </Button>

      <AvenueImg fluid={bg} />
    </NotFoundComponentContainer>
  )
}

export default NotFoundComponent
