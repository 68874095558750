import styled from "styled-components"
import { device } from "breakpoints"

export const NotFoundComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: calc(100vh - 72px);
  min-height: 1000px;
  /* justify-content: center; */

  @media ${device.desktop} {
    height: calc(100vh - 128px);
    min-height: auto;
    /* min-height: 1000px; */
  }

  h1 {
    color: white;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    margin-top: 150px;
    @media ${device.desktop} {
      margin-bottom: 25px;
    }
  }

  p {
    color: white;
    margin-bottom: 30px;
    font-size: 18px;
    position: relative;
    z-index: 2;
    @media ${device.desktop} {
      margin-bottom: 50px;
      font-size: 22px;
    }
  }

  a {
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .avenueImg {
    position: absolute !important;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`
