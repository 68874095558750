import React from "react"
import { ReactComponent as HomeIcon } from "icons/home.svg"
import { ReactComponent as Arrow } from "icons/arrow-left.svg"

import {
  BreadcrumbsContainer,
  BreadcrumbsLink,
  BreadcrumbsArrow,
} from "./style"

const Breadcrumbs = ({ ancestors }) => {

  let orderedAncestors = ancestors ? ancestors.sort((a, b) => a.depth - b.depth) : null; 

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsLink to="/">
        <HomeIcon />
      </BreadcrumbsLink>
      {orderedAncestors?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <BreadcrumbsArrow>
              <Arrow />
            </BreadcrumbsArrow>
            <BreadcrumbsLink to={item.uri}>{item.title}</BreadcrumbsLink>
          </React.Fragment>
        )
      })}
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
