import React from "react"
import styled from "styled-components"
import { device } from "breakpoints"
import { Text, Box } from "ui"

export const FormLabel = (props) => {
  return (
    <Text
      display="block"
      as="label"
      fontSize={[16]}
      marginBottom={[10]}
      fontWeight="bold"
      {...props}
    />
  )
}

export const SmallLabel = (props) => {
  return <Text as="label" fontSize={[14]} fontWeight="bold" {...props} />
}

export const FormFieldsWrapper = styled(Box)`
  > * {
    margin-bottom: 20px;

    @media ${device.desktop} {
      margin-bottom: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const RadioList = styled(Box)`
  > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
