import React from "react"
import { CheckboxContainer, Input, Checkmark } from "./style"
import { SmallLabel } from "ui/Form/style"

const Checkbox = React.forwardRef(
  ({ name, value, defaultChecked, className, label, onChange }, ref) => {
    return (
      <CheckboxContainer className={className}>
        <SmallLabel htmlFor={value}>{label || value}</SmallLabel>
        <Input
          id={value}
          name={name}
          value={value}
          type="checkbox"
          defaultChecked={defaultChecked}
          ref={ref}
          onChange={(e) => onChange && onChange(e)}
        />
        <Checkmark className="checkmark" />
      </CheckboxContainer>
    )
  }
)

export default Checkbox
