import React from "react"
import { IconSVG } from "./style"
import * as icons from "./icons.js"

const Icon = ({
  name,
  size = 40,
  color,
  hoverColor,
  height,
  width,
  w,
  h,
  transform,
  onClick,
  title,
  className,
  ...styleProps
}) => {
  const forcedUnderscoredName = name && name.replace(/-/g, "_")
  const renderedIcon = icons[forcedUnderscoredName]

  if (renderedIcon) {
    return (
      <IconSVG
        as={renderedIcon}
        height={height || h || size}
        width={width || w || size}
        className={`${className} icon`}
        color={color}
        hoverColor={hoverColor}
        transform={transform}
        onClick={onClick}
        title={title}
        {...styleProps}
      />
    )
  } else return "invalid-icon"
}

export default Icon
