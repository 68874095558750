import styled from "styled-components"
import { device } from "breakpoints"
import AvenueLink from "ui/AvenueLink"
import AvenueImg from "ui/AvenueImg"
import { Text, Box } from "ui"

export const FooterContainer = styled.div`
  /* margin-top: 100px; */
  padding: 40px 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;

  @media ${device.desktop} {
    padding: 80px 0 60px 0;
  }
`

export const LogoWrapper = styled(AvenueLink)`
  align-self: flex-start;
  height: auto;
  margin-bottom: 16px;

  > svg,
  > img {
    width: 103px;
    height: 44px;
    display: block;

    @media ${device.desktop} {
      width: 135px;
      height: 58px;
    }
  }
`

export const CompanyLogo = styled(AvenueImg)`
  width: 157px;
  height: 43px;

  @media ${device.desktop} {
    width: 157px;
    height: 43px;
    margin-top: -6px;
  }
`

export const FooterLink = styled(AvenueLink)`
  font-size: 12px;
  color: #163c67;
  font-weight: 600;
  margin-right: 30px;
  @media ${device.desktop} {
    font-weight: 500;
    text-align: right;
    line-height: 27px;
    margin-right: 16px;
  }

  :last-child {
    margin-right: 0;
  }

  :hover {
    color: ${(props) => props.theme.colors.orange};
    transition: 80ms;
  }
`

export const FooterLinksMenu = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media ${device.desktop} {
    margin-top: 25px;
    justify-content: flex-end;
  }
`

export const Copyright = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  flex-shrink: 0;
  p {
    font-size: 12px;
    font-weight: 600;
    color: #a9b1b9;

    a {
      font-weight: 600;
      text-decoration: none;

      :hover {
        opacity: 0.6;
        transition: 80ms;
      }
    }
  }
`

export const FooterText = styled(Text)`
  @media screen and (-ms-high-contrast: none) {
    max-width: 340px;
  }
`

export const LogosWrapper = styled(Box)`
  gap: 15px;
  @media ${device.desktop} {
    gap: 22px;
  }
`
