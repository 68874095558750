import React, { useState } from "react"

import {
  SelectComponent,
  ArrowContainer,
  CustomControl,
  CustomSingleValue,
} from "./style"
import { ReactComponent as Arrow } from "./down-arrow.svg"

const SelectInput = ({
  value,
  name,
  options,
  debug,
  onChange,
  isSearchable = false,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(value)

  console.log("options:", options)
  console.log("selectedOption:", selectedOption)

  const Control = ({ children, innerProps }) => {
    return <CustomControl {...innerProps}>{children}</CustomControl>
  }

  const SingleValue = ({ children }) => {
    return <CustomSingleValue>{children}</CustomSingleValue>
  }

  const DropdownIndicator = (props) => {
    return (
      <ArrowContainer open={props.selectProps.menuIsOpen}>
        <Arrow />
      </ArrowContainer>
    )
  }

  const handleChange = (e) => {
    console.log(e)
    setSelectedOption(e)
    onChange && onChange(e)
  }

  return (
    <SelectComponent
      id={name}
      name={name}
      options={options}
      value={selectedOption}
      components={{
        Control,
        SingleValue,
        Placeholder: SingleValue,
        DropdownIndicator,
        // Option,
      }}
      styles={{
        //resetting option styles
        option: () => ({}),
      }}
      onChange={(e) => handleChange(e)}
      menuIsOpen={debug}
      classNamePrefix="select"
      autoFocus={false}
      isSearchable={isSearchable}
      {...rest}
    />
  )
}

export default SelectInput
