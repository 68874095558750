import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "ui/MainColumn"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  z-index: 3;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 72px;
  align-items: center;

  @media ${device.desktop} {
    height: 128px;
    height: ${(props) => props.sticky && "100px"};
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;

  > svg,
  > .avenueImg {
    width: 163px;
    height: 36px;

    @media ${device.desktop} {
      width: 190px;
      height: 41px;
    }

    @media ${device.large} {
      width: 236px;
      height: 52px;
    }

    display: block;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Nav = styled.nav`
  position: absolute;
  bottom: 0;
  transform: translate3D(-50%, 100%, 0);
  left: 50%;
  margin: 0;
  background: #fff;
  width: 100vw;
  display: ${(props) => (props.isNavShown ? "flex" : "none")};
  z-index: 10;

  @media ${device.desktop} {
    display: flex;
    position: static;
    bottom: auto;
    left: auto;
    width: 100%;
    background: none;
    transform: none;
    justify-content: flex-end;
    margin-top: 10px;
    margin-top: ${(props) => props.sticky && 0};
  }

  @media ${device.large} {
    margin-top: 22px;
    margin-top: ${(props) => props.sticky && 0};
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding-top: 17px;
  padding-bottom: 17px;
  @media ${device.desktop} {
    flex-direction: row;
    padding: 0px;
  }
`

export const QuickSearchWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  max-height: 60px;
  height: 100%;
  opacity: 1;
  transition: 110ms;

  :hover {
    opacity: 0.7;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 30px;
  height: 16px;
  position: relative;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: white;
  background: ${(props) => props.sticky && "#163C67"};
  position: absolute;
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;

  opacity: ${(props) => (props.isNavShown ? 0 : 1)};
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 16px, 0) rotate(0deg)"};
  display: block;
`
