import React from "react"
import PropTypes from "prop-types"
import Categories from "./Categories"
import moment from "moment"
import TitleComponent from "./TitleComponent"
import {
  TeaserContainer,
  TeaserImg,
  InfoWrapper,
  OuterLink,
  Excerpt,
  Tag,
  Date,
  TeaserButton,
} from "./style.js"

const Teaser = ({
  className,
  children, //custom components that get placed within InfoWrapper
  img, // (gatsby-image fluid)
  title, // (string)
  link, // (string)
  categories, // (array)
  excerpt, // (string)
  tag, // (string)
  date, // (string)
  button, // (object), pass in the same properties as our Button component and a button will be rendered in the InfoWrapper
  testing, // (boolean), set to true to disable the Links making the whole teaser clickable, better for dev inspections
  row, // (boolean), left to right format, image is 50% by default, but this can be changed
  centered, // (boolean), will center the
  outerLink, // (booelean), if this is true, the link inside the title will be disabled, and the OuterLink will be rendered to make the whole teaser clickable
  overlapped, // (boolean), overlaps InfoWrapper over the image
}) => {
  // console.log("img:", img)

  return (
    <TeaserContainer
      className={className}
      row={row}
      testing={testing ? "true" : "false"}
      outerLink={outerLink}
    >
      <TeaserImg imgAspectRatio={img?.aspectRatio} fluid={img} row={row} />

      <InfoWrapper centered={centered} row={row} overlapped={overlapped}>
        {title && (
          <TitleComponent
            title={title}
            link={link}
            centered={centered}
            outerLink={outerLink}
          />
        )}
        {tag && <Tag> {tag}</Tag>}
        {date && <Date> {moment(date).format("DD MMM YYYY")}</Date>}

        {categories && <Categories categories={categories} />}
        {excerpt && <Excerpt centered={centered}>{excerpt}</Excerpt>}
        {button && <TeaserButton {...button} />}
        {children}
      </InfoWrapper>

      {outerLink && <OuterLink to={link} />}
    </TeaserContainer>
  )
}

export default Teaser

Teaser.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  img: PropTypes.object,
}
