import styled from "styled-components"
import { device } from "utils/breakpoints"
import {
  compose,
  space,
  position,
  typography,
  layout,
  flexbox,
} from "styled-system"

export const FormErrorMessage = styled.p`
  display: ${(props) => !props.show && "none"};
  font-size: 14px;
  font-weight: 300;
  position: relative;

  color: #D83737;
  @media ${device.desktop} {
    font-size: 16px;
  }

  ${compose(space, position, typography, layout, flexbox)}
`
