import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`

/* The switch - the box around the slider */
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  cursor: pointer;
  background-color: #ccc;
  background-color: ${(props) => props.toggled && "#2196f3"};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transform: ${(props) => props.toggled && "translateX(100%)"};
    border-radius: 50%;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`
