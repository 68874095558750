import styled from "styled-components"
import { device } from "utils/breakpoints.js"

const inputBackground = "#F8F8F8"

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  /* padding settings */
  padding: 0 20px;
  padding-top: ${(props) => props.floatingLabel && "15px"};
  padding-top: ${(props) => props.textarea && "20px"};

  /* custom styles */
  color: #4a4a4a;
  font-size: 16px;
  height: 60px;
  height: ${(props) => props.textarea && "200px"};
  background: ${inputBackground};
  border-bottom: 2px solid
    ${(props) =>
      props.hasError
        ? "#D83737"
        : props.fieldActive
        ? props.theme.secondaryColor
        : "#A9B1B9"};

  @media ${device.desktop} {
    /* padding settings */
    padding: 0 28px;
    padding-top: ${(props) => props.floatingLabel && "15px"};
    padding-top: ${(props) => props.textarea && "20px"};

    /* custom styles */
    height: 70px;
    height: ${(props) => props.textarea && "200px"};
    font-size: 20px;
  }

  //svg prefix styles
  svg {
    width: 24px;
    height: 24px;
    order: -1;
    margin-right: 15px;

    path {
      transition: 100ms;
    }
  }

  input:focus {
    ~ svg {
      path {
        fill: ${(props) => props.theme.primaryColor};
      }
    }
  }
`

export const Input = styled.input`
  display: block;
  outline: none;
  border: none;
  resize: none;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 70px ${inputBackground} inset !important;
  }

  ::placeholder {
    opacity: 1;
  }
`

export const FloatingLabel = styled.label`
  font-size: 16px;
  color: black;
  position: absolute;
  top: ${(props) =>
    props.fieldActive ? "14px" : props.textarea ? "30px" : "50%"};
  left: ${(props) => (props.icon ? "54px" : "13px")};
  transition: 140ms;
  transform: ${(props) =>
    props.fieldActive ? "translate3d(2px, -3px, 0)" : "translate3d(0,-50%,0)"};
  font-size: ${(props) => (props.fieldActive ? "12px" : "16px")};
  opacity: ${(props) => (props.fieldActive ? "0.5" : 1)};
  @media ${device.desktop} {
    top: ${(props) =>
      props.fieldActive ? "17px" : props.textarea ? "40px" : "50%"};
    left: ${(props) => (props.icon ? "60px" : "18px")};
    font-size: ${(props) => (props.fieldActive ? "14px" : "20px")};
  }
`
