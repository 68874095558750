import React, { useState } from "react"
import { SmallLabel } from "../style"
import { ToggleContainer, Switch } from "./style"

const Toggle = React.forwardRef(
  ({ name, label, defaultChecked, onChange }, ref) => {
    const [toggled, setToggled] = useState(false)

    const handleChange = (e) => {
      setToggled(e.target.checked)
      onChange && onChange(e)
    }
    return (
      <ToggleContainer>
        <Switch className="switch" toggled={toggled}>
          <input
            type="checkbox"
            onChange={(e) => handleChange(e)}
            name={name}
            defaultChecked={defaultChecked}
            ref={ref}
          />
        </Switch>

        {label && <SmallLabel ml={15}>{label}</SmallLabel>}
      </ToggleContainer>
    )
  }
)

export default Toggle
