import React from "react"
import { FormErrorMessage } from "./style"
const ErrorMessage = ({
  show,
  errors,
  name,
  className,
  message,
  ...styleProps
}) => {
  return (
    <FormErrorMessage
      className={className}
      show={show || (errors && errors[name])}
      {...styleProps}
    >
      {(show || errors) && (message || errors[name]?.message)}
    </FormErrorMessage>
  )
}

export default ErrorMessage
