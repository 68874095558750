import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "ui/AvenueImg"

export const HeaderContainer = styled.div`
  width: 100%;
  background: black;
  position: relative;
  margin: 0 auto;
  margin-bottom: 40px;

  @media ${device.desktop} {
    margin-bottom: 80px;
    margin-bottom: ${(props) => props.variant === "home" && 0};
    padding-bottom: ${(props) => props.variant === "home" && "60px"};
  }

  @media (min-width: 1600px) {
    padding-bottom: ${(props) => props.variant === "home" && "174px"};
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 323px;
    left: 0px;
    top: 0px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.55) 13.73%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.55) 13.73%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 0;
`
export const HeroImage = styled(AvenueImg)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
`

export const HeaderTextWrapper = styled.div`
  position: relative;
  z-index: 4;
  padding: 100px 0 35px;

  @media ${device.tablet} {
    max-width: 650px;
    padding: 55px 0 50px;
    padding: ${(props) => props.variant === "home" && " 197px 0 183px;"};
  }

  @media ${device.desktop} {
    padding: ${(props) => props.variant === "home" && "70px 0 183px;"};
  }

  @media (min-width: 1600px) {
    padding: 100px 0 90px;
    padding: ${(props) => props.variant === "home" && " 197px 0 183px;"};
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 111%;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-shadow: 0px 0px 35px rgba(0, 0, 0, 0.8);
    margin-bottom: 13px;

    @media ${device.desktop} {
      font-size: 60px;
      font-size: ${(props) => props.variant === "home" && "80px"};

      line-height: 100%;
      margin-bottom: 30px;
    }
  }
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 133%;
    color: #ffffff;

    @media ${device.desktop} {
      font-size: 24px;
      line-height: 136%;
      margin-top: -14px;
    }
  }
`
