import styled from "styled-components"
import Select from "react-select"
import { device } from "breakpoints"

import { layout, space } from "styled-system"

export const SelectComponent = styled(Select)`
  width: 100%;

  ${layout}
  ${space}
  
  .css-1hwfws3 {
    padding: 0;
  }

  .select__value-container {
    padding: 0;
    border-radius: 0;
  }

  .select__indicator-separator {
    display: none;
  }
  .select__menu {
    margin-top: 0;
  }
  .select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* OPTION STYLES */
  .select__option {
    color: #9e1164;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    padding: 20px;
    cursor: pointer;

    :hover {
      background-color: #9e1164;
      color: white;
    }

    &--is-selected {
      font-weight: 600;
      background-color: #9e1164;
      color: white;
    }
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    transform: rotateX(${(props) => (props.open ? "180deg" : "0")});
    transition: ${(props) => (props.open ? "300ms" : "300ms")};
    path {
      stroke: white;
    }
  }
`

export const CustomSingleValue = styled.div`
  span {
    font-weight: 600;
    color: white;
  }
  font-weight: 600;
  color: white;
`

export const CustomControl = styled.div`
  display: flex;
  background-color: #9e1164;
  height: 50px;
  cursor: pointer;
  padding: 0 20px;

  :hover {
    border-color: 0;
    background-color: #84004b;
  }
`
