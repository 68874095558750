const requiredString = "This is a required field"

export const DEFAULT_ERROR_MESSAGES = {
  //strings
  "string.empty": requiredString,
  "string.base": requiredString,
  "string.min": `This field has a minimum length of {#limit} characters`,
  "string.max": `This field has a maximum length of {#limit} characters`,
  //arrays
  "array.min": `You must select at least {#limit} item`,
  "array.max": `You can select a maximum of {#limit} items `,
  //general
  "any.required": requiredString,
  "any.only": requiredString,
  "object.base": requiredString,
  //numbers
  "number.base": requiredString,
  //dates
  "date.base": "You must set a date",
  "date.greater": "Your end date must be after the starting date",
  //emails
  "string.email": "That is not a valid email address",
}

export const RATE_ERROR_MESSAGES = {
  "number.base": "You must set a rate",
  "number.required": "You must set a rate",
  "number.min": "Your rate must be at least ${#limit}",
  "number.max": "Your rate cannot exceed ${#limit}",
}

export const BULK_SESSION_ERROR_MESSAGES = {
  "number.base": "You must set a number of sessions",
  "number.required": "You must set a number of sessions",
}

export const YEAR_ERROR_MESSAGES = {
  "number.base": "You must select a year",
  "number.base": "You must select a year",
  "number.min": "Your end year must be after the starting year",
}

export const EMAIL_ERROR_MESSAGES = {
  "string.base": `You are required to set an email`,
  "string.empty": `You are required to set an email`,
  "string.email": "That is not a valid email address",
}

export const PASSWORD_ERROR_MESSAGES = {
  "string.base": `You are required to set a password`,
  "string.empty": `You are required to set a password`,
  "string.min": `Your password must be atleast {#limit}  characters`,
  "string.max": `Your password cannot be greater than {#limit} characters`,
}
