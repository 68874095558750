import React from "react"

export const menuOrder = (menu) => {
  //

  menu.forEach(function (part) {
    if (part.wpChildren.nodes.length) {
      part.wpChildren.nodes.sort((a, b) => a.menuOrder - b.menuOrder)
    }

    part.wpChildren.nodes.forEach(function (part2) {
      if (part2.wpChildren.nodes.length) {
        part2.wpChildren.nodes.sort((a, b) => a.menuOrder - b.menuOrder)
      }
      
    })
    
  })
  
  return menu
}
