import styled from "styled-components"
import { Link } from "gatsby"

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

export const BreadcrumbsLink = styled(Link)`
  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  svg {
    width: 14px;
    height: 16px;
  }
`

export const BreadcrumbsArrow = styled.div`
  margin: 0 10px;
  svg {
    width: 8px;
    height: 12px;
    transform: scale(-1);
  }
`
