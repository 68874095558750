import styled from "styled-components"
import { compose, space, layout } from "styled-system"

const allStyledProps = compose(space, layout);

const Spacer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
      !allStyledProps.propNames.includes(prop)
})`
  width: 100%;
  ${allStyledProps}
`
export default Spacer
