import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import "./typography.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "ui/Header"
import { Footer } from "ui/Footer"
import { Helmet } from "react-helmet"

const Layout = ({ variant, children, pageContext }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
      </Helmet>
      <div className="app-wrapper">
        <Header variant={variant} pageContext={pageContext} />
        <main>{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
