import styled from "styled-components"
import AvenueLink from "ui/AvenueLink"

import { device } from "breakpoints"

import {
  compose,
  space,
  layout,
  position,
  typography,
  color,
  flexbox,
} from "styled-system"

const styledSystemProps = compose(
  space,
  position,
  layout,
  typography,
  color,
  flexbox
)

export const AvenueButton = styled(AvenueLink).withConfig({
  shouldForwardProp: (prop) => !styledSystemProps.propNames.includes(prop),
})`
  display: inline-flex;
  width: auto;
  transition: 120ms ease 0s;
  outline: none;
  border: none;
  justify-content: center;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  padding: 13px 25px;
  min-height: ${(props) => props.minHeight || "60px"};
  min-width: ${(props) => props.minWidth || "186px"};
  max-height: ${(props) => props.maxHeight || "68px"};
  max-width: ${(props) => props.maxWidth || "255px"};
  font-weight: 600;
  border-radius: 2px;
  font-size: 16px;
  @media ${device.desktop} {
    padding: 13px 33px;

    font-size: 18px;
    min-height: ${(props) => props.minHeight || "68px"};
    min-width: ${(props) => props.minWidth || "219px"};
  }

  /* default */
  color: white;
  background: ${(props) => props.theme.primaryColor};
  border: 2px solid transparent;

  :hover {
    background: ${(props) =>
      props.alternHover ? "white" : props.theme.secondaryColor};
    color: ${(props) =>
      props.alternHover ? props.theme.primaryColor : "white"};
    svg {
      g,
      path {
        fill: white;
      }
    }
  }

  /* white */
  &.white {
    background: white;
    color: ${(props) => props.theme.primaryColor};

    :hover {
      background: ${(props) => props.theme.secondaryColor};
      color: white;

      svg {
        g,
        path {
          fill: white;
        }
      }
    }
  }

  ${styledSystemProps}
`

export const Icon = styled.div`
  width: 20px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    margin-left: 15px;
  }
  svg {
    width: 100%;
    height: auto;
    transition: 120ms ease 0s;
  }
`

export const Label = styled.span`
  position: relative;
  white-space: nowrap;
`
