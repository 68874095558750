import styled from "styled-components"
import { device } from "breakpoints"
import quoteMark from "icons/quotation.svg"

export const ContentWrapper = styled.div`

  >h1,
  >h2 {
    margin-bottom: 18px;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
  }


  >h3,
  >h4,
  >h5,
  >h6 {
    margin-top:30px;
    margin-bottom: 18px;

    @media ${device.desktop} {
      margin-top:50px;
      margin-bottom: 20px;
    }
  }


 
  > p, li {
    a {
      display: inline-block;
      position: relative;
      color: ${(props) => props.theme.linkColor};
      background-image: linear-gradient(to right, #DC5F1F, #DC5F1F);
      background-position: 0 95%;
      font-weight:400;
      background-repeat: no-repeat;
      background-size: 100% 0px;

      :hover {
      background-size: 100% 1px;

      }
    }
  }

  > p {
    margin-bottom: 24px;
    @media ${device.desktop} {
      margin-bottom: 27px;
    }
    
    &.has-medium-font-size {
      color: #163C67;
      font-size: 20px;
      line-height: 28px;
      @media ${device.desktop} {
      font-size:24px;
      }
    }

  }

  > ul, > ol {
    list-style: none;
    margin-left: 29px;
    margin-bottom: 25px;

    @media ${device.desktop} {
      margin-left: 30px;
      margin-top:35px;
      margin-bottom: 35px;
    }

    >li {
      margin-bottom:18px;
      color: #4A4A4A;
      font-weight:400;

      @media ${device.desktop} {
      font-size:18px;
      margin-bottom:28px;
      }
    }
  }
  
  > ul {
    > li {
    position: relative;
      ::before {
        content: "";
        height: 7px;
        width: 7px;
        background-color: ${(props) => props.theme.secondaryColor};
        display: block;
        position: absolute;
        top: 8px;
        left: -29px;

        @media ${device.desktop} {
        top: 9px;
        }
      }
    }
  }

/* ol with numbers + dots  */

  > ol {
    counter-reset:counter;
    > li {
    counter-increment: counter;
    position: relative;
      ::before {
        content: counter(counter);
        position: absolute;
        left: -29px;
        top: 0;
        font-weight: 500;
        color: ${(props) => props.theme.secondaryColor};
      }
    }
  } 


 
  figure {
    width: 100%;
    margin: 0 auto;
 
    @media ${device.tablet} {
      &.alignwide,
      &.alignfull {
        margin-left: 0;
        width: 100%;
      }

      &.alignleft {
        float: left;
        margin-left: 0;
        width: 50%;
        margin-right: 20px;
        }
      &.alignright {
        float: right;
        margin-right: 0;
        width: 50%;
        margin-left: 20px;
        }
    }
  }

  figure.wp-block-image {
    margin-top: 30px;
    margin-bottom: 30px;
    @media ${device.desktop} {
      margin-bottom: 60px;
      margin-top: 60px;
    }

    img {
      width: 100%;
      display: block;
    }

    figcaption {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: ${(props) => props.theme.colors.textGrey};
      margin-top:10px;
      position: relative;

      @media ${device.desktop} {
        font-size: 14px;
        line-height: 193%;
      margin-top:15px;
      }
 
      ::before{
        position: absolute;
        content:'';
        bottom:-9px;
        left:0;
        width: 100%;
        height: 2px;
        background: ${(props) => props.theme.colors.teal};
        @media ${device.desktop} {
        bottom:-13px;
        
        }
      }
    }
  }

  figure.alignwide {
    @media ${device.large} {
      margin-left: -50px;
      margin-right: -50px;
      width: calc(100% + 100px);
    }
  }

  > blockquote {
    padding-top:10px;
    margin: 40px 0 30px 0;
    position: relative;
    padding-left: 32px;
    overflow:hidden;

    @media ${device.desktop} {
      padding-left: 67px;
    margin: 60px 0 50px 0;

    
    }

    :before {
      content: "";
      position: absolute;
      height: 40px;
      width: 23px;
      background-image: url('${quoteMark}');
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
      top: 4px;
      left: 0;
      @media ${device.desktop} {
        width: 36px;
        height:50px;
      }
    }

    p {
      font-size: 24px;
      margin-left: 0;
      color:#4A4A4A;
      line-height: 133%;
      letter-spacing: 0.02em;
      font-family: ${(props) => props.theme.headerFont};
      font-weight:300;
      @media ${device.desktop} {
      padding-top:2px;
      font-size:36px;
      letter-spacing: -0.5px;
      line-height: 114%;
      }
    }

    cite {
      font-size: 12px;
      color: #5a5a5a;
      margin-left: -45px;
      padding-left: 44px;
      position: relative;
      font-style: normal;
      display:inline-block;
      margin-top:25px;
      font-weight:400;

      @media ${device.desktop} {
        font-size: 14px;
      
      }

      ::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 2000px;
        top: 8px;
        right:-15px;
        transform:translateX(100%);
        background-color: ${(props) => props.theme.secondaryColor};

        @media ${device.desktop} {
        right:-19px;

        top: 9px;

      }

      }

      strong {
        color: ${(props) => props.theme.secondaryColor};
        font-weight:500;
      }
    }
  }
  
  /* video embed */
  figure.is-type-video {
    margin: 40px 0;
    .wp-block-embed__wrapper {
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
`
