import styled from "styled-components"
import AvenueLink from "ui/AvenueLink"
import { Button } from "../Button"
import AvenueImg from "ui/AvenueImg"
// import { device } from "breakpoints"

export const TeaserContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  justify-items: flex-start;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;

  a {
    ::before {
      content: ${(props) =>
        props.testing === "true" || props.outerLink ? "none" : `""`};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const TeaserImg = styled(AvenueImg)`
  position: relative;
  width: ${(props) => (props.row ? "50%" : "100%")};
  flex-shrink: 0;
  overflow: hidden;
  background: ${(props) => props.theme.navy};
  margin-bottom: ${(props) => (props.row ? "0" : "10px")};

  &.avenueImg__imgWrapper {
    padding-bottom: ${(props) =>
      props.imgAspectRatio && `${(1 / props.imgAspectRatio) * 100}%`};

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.row ? "flex-start" : props.centered ? "center" : "flex-start"};
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  height: 100%;
  width: 100%;
  text-align: ${(props) => (props.centered ? "center" : "left")};
  padding-right: 20px;
  padding-left: ${(props) => (props.row || props.centered ? "20px" : "0")};

  ${(props) =>
    props.overlapped &&
    `
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  `};
`

export const Title = styled.h3`
  margin-bottom: 10px;
  transition: color 100ms;
  width: 100%;

  ${TeaserContainer}:hover & {
    color: ${(props) => props.theme.primaryColor};
  }
`

export const CategoriesContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  font-weight: 600;
  grid-gap: 10px;
  margin-bottom: 10px;
`

export const Excerpt = styled.p`
  width: 100%;
`

export const Tag = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`

export const Date = styled(Tag)``

export const TeaserButton = styled(Button)``

export const OuterLink = styled(AvenueLink)`
  ::before {
    content: "" !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
  }
`
