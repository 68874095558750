import React from "react"
import { AvenueButton, Label, Icon } from "./style"

const Button = ({
  to,
  onClick,
  children,
  type,
  icon,
  width,
  minWidth,
  minHeight,
  w = "auto",
  fontSize = [16, 18],
  className,
  variant,
  ...styleProps
}) => {
  const isButton = onClick || type === "submit"
  return (
    <AvenueButton
      as={isButton && "button"}
      type={type}
      width={width || w}
      minWidth={minWidth}
      minHeight={minHeight}
      fontSize={fontSize}
      onClick={() => onClick && onClick()}
      to={to || "#"}
      className={`${className} ${variant} avenueBtn`}
      {...styleProps}
    >
      <Label className="label">{children}</Label>
      {icon && <Icon className={`icon ${variant} `}>{icon}</Icon>}

      
    </AvenueButton>
  )
}

export default Button
