const colors = {
  textGrey: "#4A4A4A",
  teal: "#00A0A1",
  blue: "#163C67",
  orange: "#DC5F1F",
  green: "#509D3C",
  grey: "#E4E4E4",
}

const primaryColor = colors.blue
const secondaryColor = colors.teal

const theme = {
  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: secondaryColor,
  btnTextColor: "#FFF",
  mobileGutter: 25,
  desktopGutter: 40,
  mainMaxWidth: 1156,
  xsGridGap: 10,
  smGridGap: 15,
  mdGridGap: 20,
  lgGridGap: 20,
  breakpoints: ["768px", "1024px", "1280px", "1660px"],
  headerFont: `"forma-djr-deck", "Arial", "sans-serif"`,
  bodyFont: `"Montserrat", "Arial", "sans-serif"`,
  colors: colors,
}

export default theme
