import React from "react"
import { NavListItem } from "./style"
import { Link } from "gatsby"

const NavItem = ({ label, path, className, sticky }) => {
  return (
    <NavListItem className={className} sticky={sticky}>
      <Link to={path}>
        <span>{label}</span>
      </Link>
    </NavListItem>
  )
}

export default NavItem
