import React from "react"
import { Grid, Row, Col } from "ui/Grid"
import { Box, Text } from "ui"
import { SocialLinks } from "ui/SocialLinks"
import { useStaticQuery, graphql } from "gatsby"
import AvenueLink from "ui/AvenueLink"
import {
  FooterContainer,
  FooterLink,
  Copyright,
  FooterLinksMenu,
  CompanyLogo,
  FooterText,
  LogosWrapper,
} from "./style"
import { MainColumn } from "../MainColumn"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        starlight: file(relativePath: { eq: "starlight-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        soapaid: file(relativePath: { eq: "soapaid.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        worthycause: file(relativePath: { eq: "worthycause.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        wp {
          siteOptions {
            siteSettings {
              footerText
              footerLinks {
                ... on WpPage {
                  title
                  uri
                }
              }
              facebookLink
              twitterLink
              linkedinLink
              instagramLink
              ftFeature {
                excerpt
                link
                title
              }
              emailEnquiry
              address
              phone
            }
          }
        }
      }
    `
  )
  const siteSettings = data?.wp?.siteOptions?.siteSettings
  const { footerText, footerLinks, ftFeature, address, phone, emailEnquiry } =
    siteSettings

  const ColTitle = (props) => {
    return <Text as="h4" mb={[15, 17]} {...props} />
  }

  return (
    <FooterContainer id="footer">
      <Grid mainColumn>
        <Row xsGap={37} mdGap={20}>
          <Col xs={12} md={4}>
            <Box ai="flex-start" pr={[null, null, 40, 100]}>
              <ColTitle>{ftFeature.title}</ColTitle>

              <FooterText fz={14} mb={[15]} maxWidth={500}>
                {ftFeature.excerpt}
              </FooterText>

              <AvenueLink className="textLink" fz={14} to={ftFeature.link}>
                <Text fz={14}>Read more</Text>
              </AvenueLink>
            </Box>
          </Col>

          <Col xs={12} md={3}>
            <Box ai="flex-start">
              <ColTitle>Contact us</ColTitle>

              <Text fz={14} mb={[15]} maxWidth={200}>
                {address}
              </Text>
              <Box dir={["row", null, "column"]} ai="flex-start">
                <Box mr={[40]} mb={[null, null, 16]}>
                  <AvenueLink className="textLink" fz={14} to={`tel:${phone}`}>
                    <Text fz={14}>{phone}</Text>
                  </AvenueLink>
                </Box>

                <AvenueLink
                  className="textLink"
                  fz={14}
                  to={`mailto:${emailEnquiry}`}
                >
                  <Text fz={14}>Email us</Text>
                </AvenueLink>
              </Box>
            </Box>

            {/* <LinkItem to={`tel:1300226565`}>Call 1300 Banjo Loans</LinkItem> */}
            {/* <LinkItem to={`mailto:general@banjoloans.com`}>Email us</LinkItem> */}
            {/* <LinkItem to={"/"}>Live Chat</LinkItem> */}
          </Col>

          <Col xs={12} md={3}>
            <ColTitle>Proudly supporting</ColTitle>

            <LogosWrapper>
              <CompanyLogo fluid={data.starlight} />
              <CompanyLogo fluid={data.worthycause} />
              <CompanyLogo fluid={data.soapaid} />
            </LogosWrapper>
          </Col>
          <Col xs={12} md={2}>
            <ColTitle>Follow Us</ColTitle>

            <SocialLinks
              instagram={siteSettings.instagramLink}
              facebook={siteSettings.facebookLink}
              linkedIn={siteSettings.linkedinLink}
              twitter={siteSettings.twitterLink}
            />
          </Col>
        </Row>
      </Grid>

      <MainColumn>
        <Box
          dir={["column", null, "row"]}
          borderTop="2px solid #16B7B8"
          mt={[40, null, 77]}
          pt={[null, null, 12]}
        >
          {footerText && (
            <Copyright dangerouslySetInnerHTML={{ __html: footerText }} />
          )}
          <FooterLinksMenu>
            {footerLinks.map((item, index) => {
              return (
                <FooterLink key={index} to={item.uri}>
                  {item.title}
                </FooterLink>
              )
            })}
          </FooterLinksMenu>
        </Box>
      </MainColumn>
    </FooterContainer>
  )
}
export default Footer
