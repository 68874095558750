import React, { useRef, useState } from "react"

import { NavBar } from "../NavBar"
import { Box, MainColumn } from "ui"
import { HeaderContainer, HeroImage, HeaderTextWrapper, Overlay } from "./style"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import styled from "styled-components"
import { NotFoundComponent } from "../NotFoundComponent"

export const StickyBox = styled(Box)`
  transition: 400ms ease-in-out;
  pointer-events: ${(props) => !props.showSticky && "none"};
  opacity: ${(props) => (props.showSticky ? 1 : 0)};
  transform: ${(props) =>
    props.showSticky ? "translateY(0)" : "translateY(-100%)"};
`
const Header = ({ variant, pageContext }) => {
  const { title, headerSettings, featuredImage } = pageContext
  const fuck = useRef(null)
  const [showSticky, setShowSticky] = useState(false)
  const pageTitle = headerSettings?.customPageTitle || title
  const heroImage = featuredImage?.node
  const subtitle = headerSettings?.hdDescription

  useScrollPosition(({ currPos }) => {
    if (-fuck?.current?.offsetTop > currPos?.y) {
      setShowSticky(true)
    } else {
      setShowSticky(false)
    }
  })

  return (
    <HeaderContainer id="header" variant={variant}>
      {heroImage && (
        <Overlay>
          <HeroImage fluid={heroImage} />
        </Overlay>
      )}
      <NavBar />

      <StickyBox
        background="white"
        position="fixed"
        width="100%"
        top={0}
        zIndex={100}
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.1)"
        showSticky={showSticky}
      >
        <NavBar sticky showSticky={showSticky} />
      </StickyBox>

      <MainColumn>
        {pageTitle && (
          <HeaderTextWrapper ref={fuck} variant={variant}>
            {pageTitle && <h1>{pageTitle}</h1>}
            {subtitle && <p>{subtitle}</p>}
          </HeaderTextWrapper>
        )}

        {variant === "404" && (
          <>
            <NotFoundComponent />
          </>
        )}
      </MainColumn>
    </HeaderContainer>
  )
}

export default Header
