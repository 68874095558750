import React from "react"

import { SocialLinksContainer } from "./style.js"
import { Icon } from "ui"

const SocialLinks = ({ facebook, linkedIn, twitter, instagram }) => {
  return (
    <SocialLinksContainer>
      {linkedIn && (
        <a href={linkedIn} target="_blank" rel="noopener noreferrer">
          <Icon name="linked_in" size={24} />
        </a>
      )}
      {facebook && (
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <Icon name="facebook" size={24} />
        </a>
      )}
      {instagram && (
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <Icon name="instagram" size={24} />
        </a>
      )}

      {twitter && (
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          <Icon name="twitter" size={24} />
        </a>
      )}
    </SocialLinksContainer>
  )
}

export default SocialLinks
