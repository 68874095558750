import React, { useState, useContext } from "react"
import { NavItem } from "./NavItem"
import Hamburger from "./Hamburger"
import { useStaticQuery, graphql } from "gatsby"
import { PreviewContext } from "context/PreviewContext"
import { menuOrder } from "helpers/menuOrder"
import { Box } from "ui"
import useInView from "react-cool-inview"
import { LogoWrapper, NavBarContainer, NavWrapper, Nav, NavList } from "./style"
import AvenueImg from "ui/AvenueImg"

const NavBar = ({ sticky }) => {
  const [isNavShown, setNavShown] = useState(false)
  const { previewData } = useContext(PreviewContext)

  const navData = useStaticQuery(
    graphql`
      query {
        logoSticky: file(relativePath: { eq: "aspen-logo-sticky.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logo: file(relativePath: { eq: "aspen-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        pages: allWpPage(
          filter: { isFrontPage: { eq: false }, parentId: { eq: null } }
          sort: { fields: menuOrder, order: ASC }
        ) {
          nodes {
            title
            uri

            pageSettings {
              hideFromNav
            }
            wpChildren {
              nodes {
                ... on WpPage {
                  title
                  menuOrder
                  uri
                  pageSettings {
                    hideFromNav
                  }
                  wpChildren {
                    nodes {
                      ... on WpPage {
                        title
                        menuOrder

                        uri
                        pageSettings {
                          hideFromNav
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let navMenu = previewData?.pages?.nodes || navData?.pages?.nodes

  navMenu = menuOrder(navMenu)

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  return (
    <Box
      borderBottom={!sticky && "1px solid rgba(255,255,255,0.4)"}
      zIndex={5}
      position="relative"
    >
      <NavBarContainer sticky={sticky}>
        <LogoWrapper to="/">
          <AvenueImg fluid={sticky ? navData.logoSticky : navData.logo} />
        </LogoWrapper>
        <NavWrapper sticky={sticky}>
          <Nav isNavShown={isNavShown} sticky={sticky}>
            <NavList>
              {navMenu?.map((item, index) => {
                if (
                  (item.title !== "Home" || item.uri !== "/") &&
                  !item.pageSettings.hideFromNav
                ) {
                  return (
                    <NavItem
                      key={index}
                      label={item.title}
                      path={item.uri}
                      sticky={sticky}
                    />
                  )
                }
              })}
            </NavList>
          </Nav>

          <Hamburger
            isNavShown={isNavShown}
            toggleShowNav={toggleShowNav}
            sticky={sticky}
          />
        </NavWrapper>
      </NavBarContainer>
    </Box>
  )
}

export default NavBar
