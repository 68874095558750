import styled from "styled-components"
import { device } from "breakpoints"
export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${device.desktop} {
    margin-top: 5px;
  }
  a {
    margin-right: 28px;
    :last-of-type {
      margin-right: 0;
    }

    :hover {
      opacity: 0.6;
      transition: 70ms;
    }
  }
`
