import React from "react"
import { Box } from "ui"
import { FormLabel } from "ui/Form/style"
import { ErrorMessage } from "../ErrorMessage"

const FormField = ({ name, errors, children, label }) => {
  return (
    <Box>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      {children}

      <ErrorMessage errors={errors} name={name} mt={10} />
    </Box>
  )
}

export default FormField
