import styled from "styled-components"
import { compose, position, space, layout } from "styled-system"

const allStyledProps = compose(space, position, layout);

export const IconSVG = styled.svg.withConfig({
  shouldForwardProp: (prop) =>
      !allStyledProps.propNames.includes(prop)
})`
  display: block;
  flex-shrink: 0;
  transition: 90ms;
  transform: ${(props) => props.transform};

  g,
  path {
    fill: ${(props) => props.color};
  }

  :hover {
    g,
    path {
      fill: ${(props) => props.hoverColor};
    }
  }

  ${allStyledProps}
`
