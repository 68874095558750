import styled from "styled-components"
import {
  compose,
  space,
  position,
  grid,
  color,
  shadow,
  background,
  border,
  layout,
  flexbox,
  typography,
  system,
} from "styled-system"

const config = {
  flexDirection: {
    property: "flexDirection",
  },
  justifyContent: {
    property: "justifyContent",
  },
  alignItems: {
    property: "alignItems",
  },
  maxWidth: {
    property: "maxWidth",
  },
}

config.direction = config.flexDirection
config.dir = config.flexDirection
config.jc = config.justifyContent
config.ai = config.alignItems
config.ai = config.alignItems
config.mw = config.maxWidth

const custom = system(config)

const allStyledProps = compose(
  space,
  position,
  color,
  shadow,
  background,
  border,
  layout,
  typography,
  flexbox,
  custom
)

const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => !allStyledProps.propNames.includes(prop),
})`
  display: flex;
  flex-direction: column;

  ${allStyledProps}
`

export default Box
