import styled from "styled-components"
import { compose, space, position, layout, color, border } from "styled-system"

export const TheLine = styled.hr`
  opacity: 1;
  border: 1px solid ${(props) => props.color || "#e7e8ea"};
  border-bottom: none;
  height: 1px;
  ${compose(space, position, color, border, layout)}
`
