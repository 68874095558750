import styled from "styled-components"
// import { device } from "utils/breakpoints"
import {
  compose,
  space,
  position,
  typography,
  color,
  background,
  border,
  layout,
  flexbox,
  system,
} from "styled-system"

const config = {
  fontSize: {
    property: "fontSize",
  },
  fontWeight: {
    property: "fontWeight",
  },
  textAlign: {
    property: "textAlign",
  },
  maxWidth: {
    property: "maxWidth",
  },
}

config.fz = config.fontSize
config.fs = config.fontSize
config.fw = config.fontWeight
config.ta = config.textAlign
config.mw = config.maxWidth

const custom = system(config)

const allStyledProps = compose(
  space,
  position,
  typography,
  color,
  background,
  border,
  layout,
  flexbox,
  custom
)

const Text = styled.p.withConfig({
  shouldForwardProp: (prop) => !allStyledProps.propNames.includes(prop),
})`
  ${allStyledProps}
`

export default Text
