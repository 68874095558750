import React from "react"
import { SmallLabel } from "../style"
import { RadioInputContainer, Input, RadioLabel, Checkmark } from "./style"

const RadioInput = React.forwardRef(
  (
    {
      name,
      value,
      label,
      className,
      defaultChecked,
      onChange,
    },
    ref
  ) => {
    return (
      <RadioInputContainer className={className}>
        <SmallLabel htmlFor={value}>{label || value}</SmallLabel>
        <Input
          id={value}
          name={name}
          value={value}
          type="radio"
          defaultChecked={defaultChecked}
          ref={ref}
          onChange={(e) => onChange && onChange(e)}
        />
        <Checkmark className="checkmark" />
      </RadioInputContainer>
    )
  }
)

export default RadioInput
