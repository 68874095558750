import React, { useState } from "react"
import { TextInputContainer, InputWrapper, Input, FloatingLabel } from "./style"
import { FormLabel } from "ui/Form/style"
import { ErrorMessage } from "../ErrorMessage"

const TextInput = React.forwardRef(
  (
    {
      name,
      placeholder,
      defaultValue,
      type,
      className,
      label,
      onChange,
      errors,
      //
      floatingLabel,
      prefix,
    },
    ref
  ) => {
    const [fieldActive, setFieldActive] = useState(false)
    const [inputValue, setInputValue] = useState(defaultValue || "")

    const hasError = errors && errors[name]

    return (
      <TextInputContainer
        type={type}
        fieldActive={fieldActive}
        className={className}
        textarea={type === "textarea"}
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <InputWrapper
          floatingLabel={floatingLabel}
          textarea={type === "textarea"}
          fieldActive={fieldActive}
          hasError={hasError}
        >
          <Input
            as={type === "textarea" && "textarea"}
            name={name}
            type={type}
            placeholder={placeholder || ""}
            defaultValue={defaultValue}
            id={name}
            onFocus={() => setFieldActive(true)}
            onBlur={() => !inputValue?.length && setFieldActive(false)}
            onChange={(e) => {
              const value = e.target.value
              setInputValue(value)
              onChange && onChange(value)
            }}
            ref={ref}
          />
          {prefix && prefix}

          {floatingLabel && (
            <FloatingLabel
              textarea={type === "textarea"}
              // icon={Icon}
              fieldActive={fieldActive}
              htmlFor={name}
            >
              {floatingLabel}
            </FloatingLabel>
          )}
        </InputWrapper>
        <ErrorMessage errors={errors} name={name} mt={[10, 15]} />
      </TextInputContainer>
    )
  }
)

export default TextInput

TextInput.defaultProps = {
  type: "text",
}
